import { ShopOutlined } from '@ant-design/icons';
import { DefaultFooter } from '@ant-design/pro-components';

const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear();
    return (
        <DefaultFooter
            style={{
                background: 'none',
            }}
            copyright={`${currentYear} Produced by Shopman Experience Department`}
            links={[
                {
                    key: 'PetZoo.VN',
                    title: 'PetZoo VN',
                    href: 'https://petzoo.vn',
                    blankTarget: true,
                },
                {
                    key: 'Shopman',
                    title: <ShopOutlined />,
                    href: 'https://shopman.vn',
                    blankTarget: true,
                },
                {
                    key: 'Shopman Pro v1.0.0',
                    title: 'Shopman Pro',
                    href: 'https://shopman.vn/pro',
                    blankTarget: true,
                },
            ]}
        />
    );
};

export default Footer;