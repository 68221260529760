import { EllipsisOutlined } from '@ant-design/icons';
import { PageContainer, ProCard, StatisticCard, Statistic } from '@ant-design/pro-components';
import { Button, Space } from 'antd';
import { ActivatePlot } from '../components/charts/ActivatePlot';
import { BarPlot } from '../components/charts/BarPlot';
import { DonutPlot } from '../components/charts/DonutPlot';
import { LinePlot } from '../components/charts/LinePlot';
import { PaymentPlot } from '../components/charts/PaymentPlot';
import { ViewPlot } from '../components/charts/ViewPlot';
import { useUsersQuery } from '../services/graphql/generated/schema';

const { Divider } = StatisticCard;

function Analysis() {
    const { loading } = useUsersQuery();

    return (
        <PageContainer
            token={{ paddingInlinePageContainerContent: 40 }}
            header={{
                title: null,
                breadcrumb: {}
            }}
            footer={[
                <Button key="3">Cài lại</Button>,
                <Button key="2" type="primary">
                    Gửi đi
                </Button>,
            ]}
            loading={loading}
        >
            <ProCard style={{ marginBlockStart: 8 }} direction="column" ghost gutter={[0, 16]}>
                <ProCard gutter={[16, 16]} wrap ghost>
                    <StatisticCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 6, xl: 6 }}
                        style={{ height: 200 }}
                        title="Tổng doanh số" tooltip="Mô tả chỉ số" extra={<EllipsisOutlined />}
                        statistic={{
                            value: 1102893,
                            suffix: "₫",
                            description: (
                                <Space>
                                    <Statistic title="Hàng tuần" value="12.3%" trend="up" />
                                    <Statistic title="Hàng ngày" value="11%" trend="down" />
                                </Space>
                            ),
                        }}
                        footer={<>
                            <Divider type="horizontal" style={{ marginInline: 0 }} />
                            <Statistic title="Doanh số hàng ngày" value="120.423" suffix="₫" />
                        </>}
                    />
                    <StatisticCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 6, xl: 6 }}
                        style={{ height: 200 }}
                        title="Lượt xem" tooltip="Mô tả chỉ số" extra={<EllipsisOutlined />}
                        statistic={{
                            value: 8864,
                            description: (
                                <Space>
                                    <Statistic title="Lượt truy cập hàng ngày" value="1234" />
                                </Space>
                            )
                        }}
                        chart={<ViewPlot />}
                    />
                    <StatisticCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 6, xl: 6 }}
                        style={{ height: 200 }}
                        title="Số lượng thanh toán" tooltip="Mô tả chỉ số" extra={<EllipsisOutlined />}
                        statistic={{
                            value: 6560,
                            description: (
                                <Space>
                                    <Statistic title="Tỷ lệ chuyển đổi" value="69" suffix="%" />
                                </Space>
                            )
                        }}
                        chart={<PaymentPlot />}
                    />
                    <StatisticCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 6, xl: 6 }}
                        style={{ height: 200 }}
                        title="Tần suất hoạt động" tooltip="Mô tả chỉ số" extra={<EllipsisOutlined />}
                        statistic={{
                            value: 78,
                            suffix: "%",
                            description: (
                                <Space>
                                    <Divider type="horizontal" style={{ marginInline: 0 }} />
                                    <Space>
                                        <Statistic title="Hàng tuần" value="12.3%" trend="up" />
                                        <Statistic title="Hàng ngày" value="11%" trend="down" />
                                    </Space>
                                </Space>
                            )
                        }}
                        chart={<ActivatePlot />}
                    />
                </ProCard>
                <ProCard
                    title="数据概览"
                    extra="2019年9月28日 星期五"
                    headerBordered
                    bordered
                >
                    <ProCard split="horizontal">
                        <ProCard split="horizontal">
                            <ProCard split="vertical">
                                <StatisticCard
                                    statistic={{
                                        title: '昨日全部流量',
                                        value: 234,
                                        description: <Statistic title="较本月平均流量" value="8.04%" trend="down" />,
                                    }}
                                />
                                <StatisticCard
                                    statistic={{
                                        title: '本月累计流量',
                                        value: 234,
                                        description: <Statistic title="月同比" value="8.04%" trend="up" />,
                                    }}
                                />
                            </ProCard>
                            <ProCard split="vertical">
                                <StatisticCard
                                    statistic={{
                                        title: '运行中实验',
                                        value: '12/56',
                                        suffix: '个',
                                    }}
                                />
                                <StatisticCard
                                    statistic={{
                                        title: '历史实验总数',
                                        value: '134',
                                        suffix: '个',
                                    }}
                                />
                            </ProCard>
                        </ProCard>
                        <StatisticCard
                            title="流量走势"
                            chart={
                                <img
                                    src="https://gw.alipayobjects.com/zos/alicdn/_dZIob2NB/zhuzhuangtu.svg"
                                    width="100%"
                                />
                            }
                        />
                    </ProCard>
                    <StatisticCard
                        title="流量占用情况"
                        chart={
                            <img
                                src="https://gw.alipayobjects.com/zos/alicdn/qoYmFMxWY/jieping2021-03-29%252520xiawu4.32.34.png"
                                alt="大盘"
                                width="100%"
                            />
                        }
                    />
                </ProCard>
                <ProCard style={{ marginBlockStart: 8 }} gutter={[16, 16]} wrap ghost>
                    <StatisticCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 12, xl: 12 }}
                        chart={<BarPlot />} />
                    <StatisticCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 12, xl: 12 }}
                        chart={<DonutPlot />} />
                </ProCard>
                <StatisticCard chart={<LinePlot />} />
            </ProCard>
        </PageContainer>
    )
}

export default Analysis;