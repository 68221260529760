import { Select } from "antd";

type props = {
    width: number
};

export default function SelectBranch({ width }: props) {
    const onChange_ChiNhanh = (value: string) => {
        console.log(`selected ${value}`);
    };

    return <Select
        showSearch
        bordered={false}
        defaultValue="tong"
        optionFilterProp="children"
        style={{
            width,
            borderRadius: 6,
            backgroundColor: "rgba(0, 0, 0, 0.06)",
        }}
        onChange={onChange_ChiNhanh}
        filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={[
            {
                label: 'Chọn chi nhánh',
                options: [
                    {
                        value: 'tong',
                        label: '[Chi nhánh Tổng]',
                    },
                    {
                        value: '123quan2',
                        label: '123 Đường 20, Thạnh Mỹ Lợi, Quận 2',
                    },
                    {
                        value: '158linhtrung',
                        label: '158 Linh Trung, Linh Trung, Thủ Đức',
                    },
                    {
                        value: '12hovanhue',
                        label: '12 Hồ Văn Huê, Phú Nhuận, TP.HCM',
                    },
                ],
            }
        ]}
    />
}