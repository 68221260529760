import { PlusCircleFilled, SearchOutlined, QuestionCircleFilled, MailOutlined } from '@ant-design/icons';
import { ProCard, ProConfigProvider, ProForm, ProFormText } from '@ant-design/pro-components';
import type { ProSettings } from '@ant-design/pro-layout';
import { ProLayout } from '@ant-design/pro-layout';
import { css } from '@emotion/css';
import { Button, FloatButton, Input, InputRef } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer';
import LanguagePicker from '../../components/LanguagePicker';

export default function ForgotPassword() {
    const { t } = useTranslation();
    const location = useLocation();
    const [pathname] = useState(location.pathname);
    const inputRef = useRef<InputRef>(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `${t('page.forgotPassword.title')} - ${process.env.REACT_APP_WEBSITE_NAME}`;
        inputRef.current!.focus({
            cursor: 'all',
        });
    }, [t]);

    const [settings] = useState<Partial<ProSettings> | undefined>({
        fixSiderbar: true,
        layout: 'mix',
        splitMenus: true,
    });

    return (
        <div
            id="test-pro-layout"
            style={{
                height: '100vh',
            }}
        >
            <ProConfigProvider hashed={false}>
                <ProLayout
                    disableMobile={true}
                    suppressSiderWhenMenuEmpty
                    title={process.env.REACT_APP_LOGO_TEXT}
                    logo="/images/dog-paw.png"
                    bgLayoutImgList={[
                        {
                            src: "/images/tps-609-606.png",
                            left: 85,
                            bottom: 100,
                            height: '303px',
                        },
                        {
                            src: "/images/tps-609-606.png",
                            bottom: -68,
                            right: -45,
                            height: '303px',
                        },
                        {
                            src: "/images/tps-884-496.png",
                            bottom: 0,
                            left: 0,
                            width: '331px',
                        },
                    ]}
                    location={{
                        pathname,
                    }}
                    avatarProps={{
                        render: () => {
                            return [
                                <LanguagePicker key="languagePicker" />
                            ]
                        }
                    }}
                    actionsRender={(props) => {
                        if (props.isMobile) return [];
                        return [
                            props.layout !== 'side' && document.body.clientWidth > 1400 ? (
                                <div
                                    key="SearchOutlined"
                                    aria-hidden
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginInlineEnd: 24,
                                    }}
                                    onMouseDown={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    <Input
                                        style={{
                                            borderRadius: 4,
                                            marginInlineEnd: 12,
                                            backgroundColor: 'rgba(0,0,0,0.03)',
                                        }}
                                        prefix={
                                            <SearchOutlined
                                                style={{
                                                    color: 'rgba(0, 0, 0, 0.15)',
                                                }}
                                            />
                                        }
                                        placeholder="Tìm kiếm..."
                                        bordered={false}
                                    />
                                    <PlusCircleFilled
                                        style={{
                                            color: 'var(--ant-primary-color)',
                                            fontSize: 24,
                                        }}
                                    />
                                </div>
                            ) : undefined,
                            <QuestionCircleFilled key="QuestionCircleFilled" />
                        ];
                    }}
                    headerTitleRender={(logo, _title, _) => {
                        const defaultDom = (
                            <Link to="/">
                                {logo}
                                <span className={css`
                                    font-family: "f Fallow", sans-serif;
                                    color: ${process.env.REACT_APP_THEME_PRIMARY_COLOR};
                                    margin-left: 5px;
                                `}>{process.env.REACT_APP_LOGO_TEXT}</span>
                            </Link>
                        );
                        if (document.body.clientWidth < 1400) {
                            return defaultDom;
                        }
                        if (_.isMobile) return defaultDom;
                        return (
                            <>
                                {defaultDom}
                            </>
                        );
                    }}
                    {...settings}
                    footerRender={() => (<Footer />)}
                >
                    <ProForm submitter={false}>
                        <ProCard
                            title={<div style={{ fontSize: 20 }}>{t('page.forgotPassword.title')}</div>}
                            headerBordered
                            bordered
                            boxShadow
                            direction='column'
                            gutter={[{ xs: 8, sm: 8, md: 16, lg: 24, xl: 32 }, 16]}
                            style={{
                                marginBlockStart: 60,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                maxWidth: 500,
                                height: '255px',
                                fontSize: 16,
                            }}
                            bodyStyle={{ height: 'unset', paddingBlock: 'unset' }}
                            actions={
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'right',
                                        padding: 24,
                                        flex: 1,
                                        gap: 8,
                                    }}
                                >
                                    <Button type="default" size="large" key="cancel" onClick={() => navigate('/')}>
                                        {t('page.forgotPassword.formCancel')}
                                    </Button>
                                    <Button type="primary" size="large" key="submit" htmlType="submit">
                                        {t('page.forgotPassword.formResetPassword')}
                                    </Button>
                                </div>
                            }
                        >
                            <p>{t('page.forgotPassword.describe')}</p>
                            <ProFormText
                                name="Email"
                                fieldProps={{
                                    size: 'large',
                                    ref: inputRef,
                                    prefix: <MailOutlined className="prefixIcon" />,
                                }}
                                placeholder={t('page.forgotPassword.inputEmail') || undefined}
                                rules={[
                                    {
                                        required: true,
                                        message: t('page.forgotPassword.plsInputEmail') || undefined,
                                    },
                                    {
                                        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$\b/,
                                        message: t('page.forgotPassword.errInvalidEmail') || undefined,
                                    },
                                ]}
                            />
                        </ProCard>
                    </ProForm>
                </ProLayout>
            </ProConfigProvider >
            <FloatButton.BackTop />
        </div >
    );
};