import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';

const Server = () => {
    const { t } = useTranslation();
    return (
        <Result
            status="500"
            title="500"
            subTitle={t("page.error.server.subTitle")}
            extra={<Button type="primary" href="/"
                style={{ backgroundColor: process.env.REACT_APP_THEME_PRIMARY_COLOR }}>
                {t("page.error.server.retry")}
            </Button>}
        />)
};

export default Server;