import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/plots';

export const PaymentPlot = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        asyncFetch();
    }, []);

    const asyncFetch = () => {
        fetch('https://gw.alipayobjects.com/os/bmw-prod/360c3eae-0c73-46f0-a982-4746a6095010.json')
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => {
                console.log('fetch data failed', error);
            });
    };
    const config = {
        data,
        xField: 'timePeriod',
        yField: 'value',
        xAxis: false || undefined,
        yAxis: false || undefined,
        autoFit: true,
        height: 50
    };

    return <Column {...config} />;
};