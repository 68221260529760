import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle={t("page.error.notFoundPage.subTitle")}
      extra={
        <Button type="primary" onClick={() => navigate('/')}>
          {t("page.error.notFoundPage.return")}
        </Button>
      }
    />)
};

export default NotFound;