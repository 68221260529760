import { Suspense } from "react";
import { ConfigProvider, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";
import { useMeQuery, User } from "./services/graphql/generated/schema";
import ProtectedRoute, { ProtectedRouteProps } from "./utils/ProtectedRoute";
import viVN from 'antd/es/locale/vi_VN';
import enUS from 'antd/es/locale/en_US';
import { css } from '@emotion/css';
import LayoutAdmin from "./components/LayoutAdmin";
import Login from "./pages/User/Login";
import Sale from "./pages/Sale";
import Overview from "./pages/Overview";
import Analysis from "./pages/Analysis";
import Monitor from "./pages/Monitor";
import ProductCategory from "./pages/ProductCategory";
import ProductPriceBook from "./pages/ProductPriceBook";
import ErrorNotFound from "./pages/Error/NotFound";
import ErrorServer from "./pages/Error/Server";
import ForgotPassword from "./pages/User/ForgotPassword";

function App() {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;
    const { data, loading, error } = useMeQuery();
    const isAuthenticated = data?.me ? true : false;

    if (loading)
        return <Spin tip={t("app.loading")}
            className={css`
                .ant-spin-dot-item {
                    background-color: ${process.env.REACT_APP_THEME_PRIMARY_COLOR};
                }
                .ant-spin-text { color: ${process.env.REACT_APP_THEME_PRIMARY_COLOR}; }
            `}
            style={{
                minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}
            size="large" >
            <div className="content" />
        </Spin>;

    if (error) return <ErrorServer />

    const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
        isAuthenticated,
        authenticationPath: '/user/login',
    };

    type PrivateRouteProps = {
        outlet: JSX.Element;
    };

    function PrivateRoute({ outlet }: PrivateRouteProps) {
        return isAuthenticated ? <Navigate to="/" /> : <>{outlet}</>;
    }

    return (
        <Suspense fallback="loading">
            <ConfigProvider
                locale={currentLang === 'vi' ? viVN : enUS}
                theme={{
                    token: {
                        colorPrimary: process.env.REACT_APP_THEME_PRIMARY_COLOR,
                        colorLink: process.env.REACT_APP_THEME_PRIMARY_COLOR,
                        colorLinkHover: process.env.REACT_APP_THEME_COLOR_LINK_HOVER,
                        colorLinkActive: process.env.REACT_APP_THEME_COLOR_LINK_ACTIVE,
                        fontSize: 16
                    },
                }}
            >
                <Routes>
                    <Route path='/' element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<LayoutAdmin currentUser={data?.me as User} />} />}>
                        <Route path='/' element={<Overview />} />
                        <Route path='admin'>
                            <Route path='dashboard'>
                                <Route path='analysis' element={<Analysis />} />
                                <Route path='monitor' element={<Monitor />} />
                            </Route>
                        </Route>
                        <Route path='product' element={<ProductCategory />} />
                        <Route path='product/pricebook' element={<ProductPriceBook />} />
                        <Route path='product/warranty' element={<Analysis />} />
                    </Route>
                    <Route path='sale' element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Sale />} />} />
                    <Route path='user/login' element={<PrivateRoute outlet={<Login />} />} />
                    <Route path='user/forgot-password' element={<PrivateRoute outlet={<ForgotPassword />} />} />
                    <Route path='*' element={<ErrorNotFound />} />
                </Routes>
            </ConfigProvider>
        </Suspense>
    )
};

export default App;  