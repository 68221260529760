import { CaretDownFilled, ExportOutlined, ImportOutlined, PlusOutlined } from '@ant-design/icons';
import { ActionType, PageContainer, ProColumns, ProSkeleton, ProTable, TableDropdown } from '@ant-design/pro-components';
import { Badge, Button, Divider, Dropdown, MenuProps, Space, Table } from 'antd';
import { useRef, useState } from 'react';
import { Product, useProductsQuery } from '../services/graphql/generated/schema';

const columns: ProColumns<Product>[] = [
  {
    title: 'Mã hàng',
    dataIndex: 'code',
    disable: true,
    copyable: true,
    ellipsis: true,
    tip: 'Mã hàng sản phẩm',
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Bắt buộc',
        },
      ],
    },
  },
  {
    title: 'Tên hàng',
    dataIndex: 'name',
    disable: true,
    ellipsis: true,
  },
  {
    title: 'Nhóm hàng',
    dataIndex: 'group',
    ellipsis: true,
    filters: true,
    onFilter: true,
    valueType: 'select',
    valueEnum: {
      all: { text: 'Tất cả' },
      open: {
        text: 'Chưa giải quyết',
        status: 'Error',
      },
      closed: {
        text: 'Đã giải quyết',
        status: 'Success',
        disabled: true,
      },
      processing: {
        text: 'Đang giải quyết',
        status: 'Processing',
      },
    },
  },
  {
    title: 'Giá bán',
    dataIndex: 'price',
    disable: true,
    search: false,
    sorter: (a, b) => a.price - b.price,
  },
  {
    title: 'Giá vốn',
    dataIndex: 'costPrice',
    sorter: (a, b) => a.costPrice - b.costPrice,
    hideInSearch: true,
  },
  {
    title: 'Tồn kho',
    dataIndex: 'stock',
    sorter: (a, b) => a.stock - b.stock,
    hideInSearch: true,
  },
  {
    title: 'KH đặt',
    dataIndex: 'order',
    sorter: (a, b) => a.order - b.order,
    hideInSearch: true,
  },
  {
    title: 'Thao tác',
    disable: true,
    ellipsis: true,
    valueType: 'option',
    key: 'option',
    render: (_text, record, _, action) => [
      <a
        key="editable"
        onClick={() => {
          action?.startEditable?.(record.code);
        }}
      >
        Sửa
      </a>,
      <a href={record.name} target="_blank" rel="noopener noreferrer" key="view">
        Xem
      </a>,
      <TableDropdown
        key="actionGroup"
        onSelect={() => action?.reload()}
        menus={[
          { key: 'copy', name: 'Sao chép' },
          { key: 'delete', name: 'Xóa', style: { color: 'red' } },
        ]}
      />,
    ],
  },
];

function ProductCategory() {
  const [num, setNum] = useState(40);
  const actionRef = useRef<ActionType>();

  const { data, loading } = useProductsQuery();
  const dataProduct = data?.prouducts as Product[];

  const items: MenuProps['items'] = [
    {
      key: 'add_product',
      label: (
        <a target="_blank" key="add_product" rel="noopener noreferrer" href="https://www.antgroup.com">
          Thêm hàng hóa
        </a>
      ),
    },
    {
      key: 'add_service',
      label: (
        <a target="_blank" key="add_service" rel="noopener noreferrer" href="https://www.aliyun.com">
          Thêm dịch vụ
        </a>
      ),
    },
    {
      key: 'add_combo',
      label: (
        <a target="_blank" key="add_combo" rel="noopener noreferrer" href="https://www.luohanacademy.com">
          Thêm combo - đóng gói
        </a>
      ),
    },
  ];

  return (
    <PageContainer
      token={{ paddingInlinePageContainerContent: num }}
      style={{ marginBlockStart: 8 }}
      header={{
        subTitle: "Danh mục hàng hóa",
        breadcrumb: {}
      }}
      extra={[
        <Dropdown key="dr_btnAddProduct" menu={{ items }} placement="bottom" arrow={{ pointAtCenter: true }}>
          <Button
            key="btnAddProduct"
            type="primary"
            onClick={() => {
              setNum(num > 0 ? 0 : 40);
            }}
          >
            <Space><PlusOutlined />Thêm mới<CaretDownFilled /></Space>
          </Button>
        </Dropdown>,
        <Button key="btnImport"><ImportOutlined />Nhập File</Button>,
        <Button key="btnExport"><ExportOutlined />Xuất File</Button>,
      ]}
    >
      {loading ? <ProSkeleton type="descriptions" /> :
        <ProTable<Product>
          columns={columns}
          actionRef={actionRef}
          cardBordered
          request={async (params = {}, sort, filter) => {
            console.log("----params request", params);
            console.log("sort", sort);
            console.log("filter", filter);
            // Here you need to return a Promise, and you can transform the data before returning it
            // If you need to transform the parameters you can change them here
            const dataReq = (params.code === undefined || null) ? dataProduct : dataProduct.filter(i => i.code.includes(params.code));

            console.log("dataReq", dataReq)

            return {
              data: dataReq,
              success: true,
              total: dataReq.length,
              params
            };
          }}
          editable={{
            type: 'multiple',
          }}
          columnsState={{
            persistenceKey: 'pro-table-singe-products',
            persistenceType: 'localStorage',
            onChange(_value) {
              //console.log('value: ', value);
            },
          }}
          rowKey="id"
          search={{
            labelWidth: 'auto',
          }}
          options={{
            setting: {
              listsHeight: 400,
            },
          }}
          rowSelection={{
            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
          }}
          tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }) => (
            <Space size={24}>
              <span>
                Đã chọn {selectedRowKeys.length} mục
                <a style={{ marginInlineStart: 8 }} onClick={onCleanSelected}>
                  Hủy chọn
                </a>
              </span>
              <span>{`Số item-1: ${selectedRows.reduce(
                (pre, _item) => pre + 3,
                0,
              )} cái`}</span>
              <span>{`Số item-2: ${selectedRows.reduce(
                (pre, _item) => pre + 5,
                0,
              )} lần`}</span>
            </Space>
          )}
          tableAlertOptionRender={() => {
            return (
              <Space size={16}>
                <a>Xoá mục đã chọn</a>
                <a>Xuất dữ liệu đã chọn</a>
              </Space>
            );
          }}
          form={{
            // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
            syncToUrl: (values, type) => {
              console.log('type =', type)

              if (type === 'get') {
                return {
                  ...values,
                  created_at: [values.startTime, values.endTime],
                };
              }

              console.log('values Form =', values)
              return values;
            },
            ignoreRules: false,

          }}
          pagination={{
            //pageSize: 5,
            onChange: (page: any) => console.log(page),
            showSizeChanger: true,
          }}
          dateFormatter="string"
          headerTitle="Tất cả sản phẩm"
          toolBarRender={() => [
            <Space>Tổng tồn kho
              <Badge
                count='99'
                style={{
                  marginBlockStart: -2,
                  marginInlineStart: 4,
                  color: '#999',
                  backgroundColor: '#eee',
                }}
              />
            </Space>,
            <Divider type='vertical' />,
            <Space>Tổng khách đặt
              <Badge
                count='399'
                style={{
                  marginBlockStart: -2,
                  marginInlineStart: 4,
                  color: '#999',
                  backgroundColor: '#eee',
                }}
              />
            </Space>,
            <Divider type='vertical' />
          ]}
        />
      }
    </PageContainer>
  )
}

export default ProductCategory;