const globalStyle = `
    @font-face {
        font-family: 'f Fallow';
        font-style: normal;
        font-weight: 400;
        src: local('f Fallow'), url('/fonts/Fallow-3zg28.woff') format('woff');
    }

    html, body, #root {
        height: 100%;
        margin: 0;
        padding: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    }

    // START - Menu Header Content Dark 
        .ant-menu-dark {
            background: rgb(29, 165, 122);
        }

        .ant-menu-dark.ant-menu-submenu>.ant-menu {
            color: #ffffff;
            background-color: rgb(29, 165, 122);
        }

        // .ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
        //     color: rgba(0,0,0,.88);  
        // }

        .ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected, .ant-menu-submenu-selected >.ant-menu-submenu-title {
            background-color: #178461;
            color: #ffffff;
        }

        .ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item:active {
            background-color: #178461;
            color: #ffffff;
        }

        .ant-menu-submenu-title span a{
            color: hsla(0,0%,100%,.65);
        }

        .ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu:hover, .ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu:active,
        .ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu span a:hover, .ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu span a:active {
            background-color: #178461;
            color: #ffffff;
        }

        .ant-menu-submenu ant-menu-submenu-horizontal ant-menu-submenu-selected {
            background-color: #178461;
            color: #ffffff;
        }

        .ant-menu-dark.ant-menu-horizontal >.ant-menu-submenu-selected {
            color: #ffffff;
            background-color: #178461;
        }

        .ant-menu .ant-menu-submenu-title{
            border-radius: 0px;
        }
        
    // END - Menu Header Content Dark 

    // START - Scrollbar
        body::-webkit-scrollbar {
            width: 6px;
            height: 6px
        }

        body::-webkit-scrollbar-track {
            background-color: rgba(50, 50, 50, .1);
            border-radius: 1em
        }

        body::-webkit-scrollbar-thumb {
            background-color: rgba(50, 50, 50, .3);
            border-radius: 1em
        }

        .ant-drawer-body::-webkit-scrollbar {
            width: 6px;
            height: 6px
        }

        .ant-drawer-body::-webkit-scrollbar-track {
            background-color: rgba(50, 50, 50, .1);
            border-radius: 1em
        }

        .ant-drawer-body::-webkit-scrollbar-thumb {
            background-color: rgba(50, 50, 50, .3);
            border-radius: 1em
        }
    // END - Scrollbar

    // // FIX - PRO COMPONENT
    // [class^="ant-statistic"], [class*=" ant-statistic"] {
    //     font-size: unset;
    // }

    // .ant-statistic .ant-statistic-content {
    //     font-size: 30px;
    // }

    // .ant-pro-card-statistic .ant-pro-card-statistic-layout-inline .ant-statistic-title, .ant-pro-card-statistic .ant-pro-card-statistic-layout-inline .ant-statistic-content, .ant-pro-card-statistic .ant-pro-card-statistic-layout-inline .ant-statistic-content-suffix, .ant-pro-card-statistic .ant-pro-card-statistic-layout-inline .ant-statistic-content-prefix, .ant-pro-card-statistic .ant-pro-card-statistic-layout-inline .ant-statistic-content-value-decimal {
    //     font-size: 14px;
    // }
`

export default globalStyle;