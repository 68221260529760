import React from 'react';
import {
    ShoppingCartOutlined, SettingFilled, MailOutlined, LogoutOutlined, SettingOutlined, UserOutlined,
    CrownFilled, AreaChartOutlined, DashboardOutlined, TeamOutlined, SyncOutlined, GoldenFilled, ReadFilled,
    ProfileOutlined,
} from '@ant-design/icons';
import { ProConfigProvider } from '@ant-design/pro-components';
import type { ProSettings } from '@ant-design/pro-layout';
import { ProLayout } from '@ant-design/pro-layout';
import { css } from '@emotion/css';
import { Badge, Button, ConfigProvider, Divider, Dropdown, FloatButton, Menu, MenuProps, Modal, Space } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import viVN from 'antd/es/locale/vi_VN';
import enUS from 'antd/es/locale/en_US';
import SelectBranch from './SelectBranch';
import LanguagePicker from './LanguagePicker';
import Footer from './Footer';
import Login from '../pages/User/Login';
import { MeDocument, MeQuery, useLogoutMutation, User } from '../services/graphql/generated/schema';

type AppProps = {
    currentUser: User
};

const MenuSelectBranch = () => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Divider
                style={{
                    margin: '0 18px 0 18px',
                    height: '1.5em',
                }}
                type="vertical"
            />
            <SelectBranch width={240} />
        </div>
    );
};

export default function LayoutAdmin({ currentUser }: AppProps) {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;
    //const navigate = useNavigate();
    const [pathname, setPathname] = useState(location.pathname);

    const [current, setCurrent] = useState('overview');
    const [heightLayoutHeader, setHeightLayoutHeader] = useState(104);

    const onClick: MenuProps['onClick'] = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    const [collapsed, setCollapsed] = useState(false);
    const [settings] = useState<Partial<ProSettings> | undefined>({
        fixSiderbar: true,
        layout: 'mix',
        splitMenus: true,
    });

    const _menuProps = {
        route: {
            path: '/',
            routes: [
                {
                    path: '/',
                    key: 'overview',
                    name: t("page.overview.title"),
                    label: <Link
                        to='/'
                        onClick={() => {
                            setPathname('/');
                            window.scrollTo(0, 0);
                        }}>{t("page.overview.title")}</Link>,
                    icon: <AreaChartOutlined />,
                },
                {
                    path: '/product',
                    key: 'product',
                    name: 'Hàng hóa',
                    label: <Link
                        to='/product'
                        onClick={() => {
                            setPathname('/product');
                            setCurrent('product');
                            window.scrollTo(0, 0);
                        }}>Hàng hoá</Link>,
                    icon: <GoldenFilled />,
                    routes: [
                        {
                            path: '/product',
                            name: 'Danh mục hàng hoá',
                            key: 'product',
                        },
                        {
                            path: '/product/pricebook',
                            name: 'Thiết lập giá',
                        },
                        {
                            path: '/product/warranty',
                            name: 'Phiếu bảo hành',
                        },
                        {
                            path: 'sub-sub-page4',
                            name: 'Kiểm kho',
                        },
                    ],
                },
                {
                    path: '/manage/trade',
                    key: 'manage_trade',
                    name: 'Giao dịch',
                    label: <Link
                        to='/manage/trade'
                        onClick={() => {
                            setPathname('/manage/trade');
                            window.scrollTo(0, 0);
                        }}>Giao dịch</Link>,
                    icon: <SyncOutlined />,
                    routes: [
                        {
                            path: '/manage/goods/category',
                            name: 'Đặt hàng',
                        },
                        {
                            path: 'sub-sub-page22',
                            name: 'Hóa đơn',
                        },
                        {
                            path: 'sub-sub-page333',
                            name: 'Vận đơn',
                        },
                        {
                            path: 'sub-sub-page45',
                            name: 'Trả hàng',
                        },
                        {
                            path: 'sub-sub-page2222',
                            name: 'Yêu cầu sữa chữa',
                        },
                        {
                            path: 'sub-sub-page36',
                            name: 'Nhập hàng',
                        },
                        {
                            path: 'sub-sub-page48',
                            name: 'Trả hàng nhập',
                        },
                        {
                            path: 'sub-sub-page40',
                            name: 'Xuát hủy',
                        },

                    ],
                },
                {
                    name: 'Đối tác',
                    path: '/manage/sproducts',
                    key: 'manage_trades',
                    label: <Link
                        to='/manage/trades'
                        onClick={() => {
                            setPathname('/manage/trades');
                            window.scrollTo(0, 0);
                        }}>Đối tác</Link>,
                    icon: <TeamOutlined />,
                    routes: [
                        {
                            path: '/manage/products',
                            name: 'Danh mục',
                        },
                        {
                            path: '/manage/products/pricebook',
                            name: 'Thiết lập giá',
                        },
                        {
                            path: 'sub-sub-page23',
                            name: 'Phiếu bảo hành',
                        },
                        {
                            path: 'sub-sub-page44',
                            name: 'Kiểm kho',
                        },
                    ],
                },
                {
                    name: 'Sổ quỹ',
                    key: 'manage_tradess',
                    path: '/manage/aproducts',
                    label: <Link
                        to='/manage/tradess'
                        onClick={() => {
                            setPathname('/manage/tradess');
                            window.scrollTo(0, 0);
                        }}>Sổ quỹ</Link>,
                    icon: <ReadFilled />,
                    routes: [
                        {
                            path: '/manage/products',
                            name: 'Danh mục',
                        },
                        {
                            path: '/manage/products/pricebook',
                            name: 'Thiết lập giá',
                        },
                        {
                            path: 'sub-sub-page3',
                            name: 'Phiếu bảo hành',
                        },
                        {
                            path: 'sub-sub-page4',
                            name: 'Kiểm kho',
                        },
                    ],
                },
                {
                    path: '/manage/tradesss',
                    key: 'manage_tradesss',
                    name: 'Báo cáo',
                    label: <Link
                        to='/manage/tradesss'
                        onClick={() => {
                            setPathname('/manage/tradesss');
                            window.scrollTo(0, 0);
                        }}>Báo cáo</Link>,
                    icon: <ProfileOutlined />,
                },
                {
                    path: '/admin/dashboard/analysis',
                    name: 'Admin',
                    label: <Link
                        to='/admin/dashboard/analysis'
                        onClick={() => {
                            setPathname('/admin/dashboard/analysis');
                            window.scrollTo(0, 0);
                        }}>Admin</Link>,
                    key: 'admin',
                    icon: <CrownFilled />,
                    access: 'canAdmin',
                    routes: [
                        {
                            path: '/admin/dashboard',
                            name: 'Dashboard',
                            icon: <DashboardOutlined />,
                            routes: [
                                {
                                    path: '/admin/dashboard/analysis',
                                    name: 'Analysis',
                                },
                                {
                                    path: '/admin/dashboard/monitor',
                                    name: 'Monitor',
                                },
                                {
                                    path: '/admin/dashboard/workplace',
                                    name: 'Workplace',
                                }
                            ],
                        },
                        {
                            path: '/admin/sub-page2',
                            name: 'Admin 2',
                            icon: <CrownFilled />,
                        },
                        {
                            path: '/admin/sub-page3',
                            name: 'Admin 3',
                            icon: <CrownFilled />,
                        },
                    ],
                },
                {
                    path: '/sale',
                    key: 'sale',
                    name: t('component.layout.sale'),
                    label: (
                        <Button type='dashed' ghost href='/sale'
                            icon={<ShoppingCartOutlined />}
                            target='_blank' rel='noopener noreferrer'>
                            {t('component.layout.sale')}
                        </Button>
                    ),
                    style: { float: 'right', color: '#ffffff' },
                },
                // {
                //     path: 'http://petzoo.vn',
                //     name: 'Máy Bán hàng - POS',
                //     icon: <CalculatorTwoTone />,
                //     isNewTab: true
                // },
            ],
        },
        location: {
            pathname: '/',
        },
        appList: [
            {
                icon: 'https://gw.alipayobjects.com/zos/rmsportal/XuVpGqBFxXplzvLjJBZB.svg',
                title: 'PetZoo.VN',
                desc: 'Trang chủ bán hàng Online',
                url: 'https://petzoo.vn/',
                target: '_blank',
            },
            {
                icon: '/images/pos-64.png',
                title: 'Máy Bán Hàng - POS',
                desc: 'Trang bán hàng - POS🧐',
                url: '/pos',
                target: '_blank',
            },
        ],
    };

    const [logoutUser] = useLogoutMutation();

    const [modal, contextHolder] = Modal.useModal();

    const confirmLogout = () => {
        modal.confirm({
            title: 'Đăng xuất',
            icon: <LogoutOutlined />,
            content: 'Bạn có muốn đăng xuất?',
            okText: 'Có',
            cancelText: 'Không',
            onOk: (async () => {
                await logoutUser({
                    update(cache, { data }) {
                        if (data?.logout) {
                            cache.writeQuery<MeQuery>({
                                query: MeDocument,
                                data: { me: null }
                            })
                        }
                    }
                });
                //navigate('user/login');
                <Login />
            }),
            maskClosable: true,
        });
    };

    return (
        <ConfigProvider
            locale={currentLang === 'vi' ? viVN : enUS}
            theme={{
                token: {
                    colorPrimary: process.env.REACT_APP_THEME_PRIMARY_COLOR,
                    colorLink: process.env.REACT_APP_THEME_PRIMARY_COLOR
                }
            }}
        >
            <div
                id='pro-layout'
                style={{
                    height: '100vh',
                }}
            >
                <ProConfigProvider hashed={false}>
                    <ProLayout
                        token={{
                            header: {
                                heightLayoutHeader,
                            },
                        }}
                        title={process.env.REACT_APP_LOGO_TEXT}
                        logo={
                            <>
                                <Link to='/' onClick={() => { setPathname('/'); setCurrent('overview') }}>
                                    <img alt='logo' style={{ width: 'auto', height: 28 }} src='/images/dog-paw.png' />
                                    <span className={css`
                                    font-family: 'f Fallow', sans-serif;
                                    color: ${process.env.REACT_APP_THEME_PRIMARY_COLOR};
                                    margin-left: 5px;
                                `}>{process.env.REACT_APP_LOGO_TEXT}</span>
                                </Link>
                            </>
                        }
                        bgLayoutImgList={[
                            {
                                src: "/images/tps-609-606.png",
                                left: 85,
                                bottom: 100,
                                height: '303px',
                            },
                            {
                                src: "/images/tps-609-606.png",
                                bottom: -68,
                                right: -45,
                                height: '303px',
                            },
                            {
                                src: "/images/tps-884-496.png",
                                bottom: 0,
                                left: 0,
                                width: '331px',
                            },
                        ]}
                        {...settings}
                        collapsed={collapsed}
                        onCollapse={(collapsed) => { setCollapsed(collapsed) }}
                        siderMenuType='group'
                        menu={{
                            collapsedShowGroupTitle: true,
                        }}
                        {..._menuProps}
                        location={{
                            pathname,
                        }}
                        headerRender={(props, defaultDom) => (
                            <>
                                {React.cloneElement(defaultDom as any, {
                                    style: {
                                        height: '56px',
                                        lineHeight: '56px',
                                    },
                                })}
                                {props.isMobile && setHeightLayoutHeader(0)}
                                {!props.isMobile &&
                                    <Menu onClick={onClick} selectedKeys={[current]} mode='horizontal'
                                        items={_menuProps.route.routes as MenuProps['items']} theme='dark'
                                        style={{
                                            backgroundColor: process.env.REACT_APP_THEME_PRIMARY_COLOR,
                                            opacity: 0.8,
                                            color: 'hsla(0,0%,100%,.65)',
                                            height: '48px',
                                            lineHeight: '48px',
                                            display: 'block'
                                        }}
                                    />
                                }
                            </>
                        )}
                        headerTitleRender={(logo, _title, _) => {
                            const defaultDom = logo;
                            // if (document.body.clientWidth < 1400) {
                            //     return defaultDom;
                            // }
                            // if (_.isMobile) return defaultDom;
                            return (
                                <>
                                    {defaultDom}
                                    <MenuSelectBranch />
                                </>
                            );
                        }}
                        headerContentRender={() => false}
                        // onMenuHeaderClick={(e) => {
                        //     setPathname('/');
                        //     e.stopPropagation();
                        //     e.preventDefault();
                        //     navigate('/');
                        //     window.scrollTo(0, 0);
                        // }}
                        menuItemRender={(item, dom, props) => (
                            <Link to={item.path as string}
                                onClick={() => {
                                    props.isMobile && setCollapsed(true);
                                    setPathname(item.path || '/');
                                    setCurrent(item.key || 'overview');
                                    window.scrollTo(0, 0);
                                }}>{dom}
                            </Link>
                        )}
                        avatarProps={{
                            src: process.env.REACT_APP_NO_AVATAR,
                            size: 'small',
                            title: currentUser.username,
                            render: (_props, dom) => {
                                return [
                                    <Dropdown
                                        menu={{
                                            items: [
                                                {
                                                    key: 'account_info',
                                                    label: (
                                                        <Link to='/account/info'>
                                                            Thông tin cá nhân
                                                        </Link>
                                                    ),
                                                    icon: <UserOutlined />
                                                },
                                                {
                                                    key: 'account_setting',
                                                    label: (
                                                        <Link to='/account/setting'>
                                                            Thiết lập cá nhân
                                                        </Link>
                                                    ),
                                                    icon: <SettingOutlined />
                                                },
                                                {
                                                    type: 'divider',
                                                },
                                                {
                                                    key: 'logout',
                                                    danger: true,
                                                    label: 'Đăng xuất',
                                                    icon: <LogoutOutlined />,
                                                    onClick: () => confirmLogout()
                                                },
                                            ],
                                        }}
                                    // dropdownRender={(menu) => (
                                    //     <div>
                                    //         {React.cloneElement(menu as React.ReactElement)}
                                    //         <Divider style={{ margin: 0 }} />
                                    //         <Space style={{ padding: 8 }}>
                                    //             <ShoppingCartOutlined key="shopping" title="Bán hàng"
                                    //                 style={{ fontSize: "18px" }} />,
                                    //             <Badge count={5}>
                                    //                 <MailOutlined key="mail" title="Hộp thư"
                                    //                     style={{ fontSize: "18px" }}
                                    //                     onClick={e => e.preventDefault()} />
                                    //             </Badge>,
                                    //             <SettingFilled key="setting" title="Cài đặt"
                                    //                 style={{ fontSize: "18px" }} />
                                    //         </Space>
                                    //     </div>
                                    // )}
                                    >
                                        {dom}
                                    </Dropdown>,
                                    <LanguagePicker />
                                ];
                            },
                        }}
                        actionsRender={(props) => {
                            if (props.isMobile) return [];
                            return [
                                // props.layout !== 'side' && document.body.clientWidth > 1400 ? (
                                //     <div
                                //         key="SearchOutlined"
                                //         aria-hidden
                                //         style={{
                                //             display: 'flex',
                                //             alignItems: 'center',
                                //             marginInlineEnd: 24,
                                //         }}
                                //         onMouseDown={(e) => {
                                //             e.stopPropagation();
                                //             e.preventDefault();
                                //         }}
                                //     >
                                //         <Input
                                //             style={{
                                //                 borderRadius: 4,
                                //                 marginInlineEnd: 12,
                                //                 backgroundColor: 'rgba(0,0,0,0.03)',
                                //             }}
                                //             prefix={
                                //                 <SearchOutlined
                                //                     style={{
                                //                         color: 'rgba(0, 0, 0, 0.15)',
                                //                     }}
                                //                 />
                                //             }
                                //             placeholder="Tìm kiếm..."
                                //             bordered={false}
                                //         />
                                //         <PlusCircleFilled
                                //             style={{
                                //                 color: 'var(--ant-primary-color)',
                                //                 fontSize: 24,
                                //             }}
                                //         />
                                //     </div>
                                // ) : undefined,
                                <ShoppingCartOutlined key="shopping" title="Bán hàng"
                                    style={{ fontSize: "18px" }} />,
                                <Badge count={5}>
                                    <MailOutlined key="mail" title="Hộp thư"
                                        style={{ fontSize: "18px" }}
                                        onClick={e => e.preventDefault()} />
                                </Badge>,
                                <SettingFilled key="setting" title="Cài đặt"
                                    style={{ fontSize: "18px" }} />,
                            ];
                        }}
                        menuExtraRender={({ isMobile }) =>
                            isMobile && (
                                <Space
                                    style={{
                                        marginBlockEnd: 16,
                                    }}
                                    align="center"
                                >
                                    <SelectBranch width={160} />
                                </Space>
                            )
                        }
                        menuFooterRender={(props) => {
                            if (props?.collapsed) return undefined;
                            return (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        paddingBlockStart: 12,
                                    }}
                                >
                                    <div>© 2023 Made with love</div>
                                    <div>by Shopman</div>
                                </div>
                            );
                        }}
                        footerRender={() => (<Footer />)}
                    >
                        <Outlet />
                        {/* <SettingDrawer
                        pathname={pathname}
                        enableDarkTheme
                        getContainer={() => document.getElementById('test-pro-layout')}
                        settings={settings}
                        onSettingChange={(changeSetting) => {
                            setSetting(changeSetting);
                        }}
                        disableUrlParams={false}
                    /> */}
                        {contextHolder}
                    </ProLayout>
                </ProConfigProvider>
            </div >
            <FloatButton.BackTop />
        </ConfigProvider >
    );
};