import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, MenuProps, message, Tag } from 'antd';

const LanguagePicker = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const inlineStyle = {
    cursor: 'pointer',
    padding: '8px 0px 8px 8px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
  };

  // const IconChangeLang = () => {
  //   return <svg
  //     viewBox="0 0 24 24"
  //     focusable="false"
  //     width="1em"
  //     height="1em"
  //     fill="currentColor"
  //     aria-hidden="true"
  //   >
  //     <path d="M0 0h24v24H0z" fill="none" />
  //     <path
  //       d="M12.87 15.07l-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z "
  //       className="css-c4d79v"
  //     />
  //   </svg>
  // }

  const items: MenuProps['items'] = [
    {
      key: 'vi',
      label: 'Tiếng Việt',
      icon: <Tag>VI</Tag>,
    },
    {
      key: 'en',
      label: 'English',
      icon: <Tag>EN</Tag>,
    },
  ];

  const onClick: MenuProps['onClick'] = ({ key }) => {
    i18n.changeLanguage(key);
    (key === 'vi') ? message.info('Đã thay đổi ngôn ngữ thành Tiếng Việt') : message.info('Changed language to English');
  };

  return (
    <Dropdown
      menu={{
        items,
        selectable: true,
        defaultSelectedKeys: [currentLang],
        onClick
      }}
      placement='bottomRight'
    >
      <div style={inlineStyle}>
        <Tag style={{ width: 32, textAlign: 'center' }}>{(currentLang === 'vi') ? 'VI' : 'EN'}</Tag>
      </div>
    </Dropdown>
  )
}

export default LanguagePicker;