import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, ProCard, StatisticCard, Statistic } from '@ant-design/pro-components';
import ProSkeleton from '@ant-design/pro-skeleton';
import { Button, Col, Row, Space, Timeline } from 'antd';
import { EllipsisOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useUsersQuery } from '../services/graphql/generated/schema'
import { LinePlot } from '../components/charts/LinePlot';
import { BarPlot } from '../components/charts/BarPlot';
import { DonutPlot } from '../components/charts/DonutPlot';
import { ActivatePlot } from '../components/charts/ActivatePlot';
import { PaymentPlot } from '../components/charts/PaymentPlot';
import { ViewPlot } from '../components/charts/ViewPlot';

const { Divider } = StatisticCard;

function Overview() {
    const { t } = useTranslation();
    const { loading } = useUsersQuery();
    const [num, setNum] = useState(40);

    return (
        loading ? <ProSkeleton type="descriptions" /> :
            <PageContainer
                token={{ paddingInlinePageContainerContent: num }}
                style={{ marginBlockStart: 8 }}
                header={{
                    title: t("page.overview.title"),
                    subTitle: t("page.overview.subTitle"),
                    breadcrumb: {}
                }}
                extra={[
                    <Button key="3">Phụ 2</Button>,
                    <Button key="2">Phụ 1</Button>,
                    <Button
                        key="1"
                        type="primary"
                        onClick={() => {
                            setNum(num > 0 ? 0 : 40);
                        }}
                    >
                        Nút chính
                    </Button>,
                ]}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                        <ProCard direction="column" ghost gutter={[0, 16]}>
                            <ProCard gutter={[16, 16]} wrap ghost>
                                <StatisticCard hoverable colSpan={{ xs: 24, sm: 12, md: 12, lg: 6, xl: 6 }}
                                    style={{ height: 200 }}
                                    title="Tổng doanh số" tooltip="Mô tả chỉ số" extra={<EllipsisOutlined />}
                                    statistic={{
                                        value: 1102893,
                                        suffix: "₫",
                                        description: (
                                            <Space>
                                                <Statistic title="Hàng tuần" value="12.3%" trend="up" />
                                                <Statistic title="Hàng ngày" value="11%" trend="down" />
                                            </Space>
                                        ),
                                    }}
                                    footer={<>
                                        <Divider type="horizontal" style={{ marginInline: 0 }} />
                                        <Statistic title="Doanh số hàng ngày" value="120.423" suffix="₫" />
                                    </>}
                                />
                                <StatisticCard hoverable colSpan={{ xs: 24, sm: 12, md: 12, lg: 6, xl: 6 }}
                                    style={{ height: 200 }}
                                    title="Lượt xem" tooltip="Mô tả chỉ số" extra={<EllipsisOutlined />}
                                    statistic={{
                                        value: 8864,
                                        description: (
                                            <Space>
                                                <Statistic title="Lượt truy cập hàng ngày" value="1234" />
                                            </Space>
                                        )
                                    }}
                                    chart={<ViewPlot />}
                                />
                                <StatisticCard hoverable colSpan={{ xs: 24, sm: 12, md: 12, lg: 6, xl: 6 }}
                                    style={{ height: 200 }}
                                    title="Số lượng thanh toán" tooltip="Mô tả chỉ số" extra={<EllipsisOutlined />}
                                    statistic={{
                                        value: 6560,
                                        description: (
                                            <Space>
                                                <Statistic title="Tỷ lệ chuyển đổi" value="69" suffix="%" />
                                            </Space>
                                        )
                                    }}
                                    chart={<PaymentPlot />}
                                />
                                <StatisticCard hoverable colSpan={{ xs: 24, sm: 12, md: 12, lg: 6, xl: 6 }}
                                    style={{ height: 200 }}
                                    title="Tần suất hoạt động" tooltip="Mô tả chỉ số" extra={<EllipsisOutlined />}
                                    statistic={{
                                        value: 78,
                                        suffix: "%",
                                        description: (
                                            <Space>
                                                <Divider type="horizontal" style={{ marginInline: 0 }} />
                                                <Space>
                                                    <Statistic title="Hàng tuần" value="12.3%" trend="up" />
                                                    <Statistic title="Hàng ngày" value="11%" trend="down" />
                                                </Space>
                                            </Space>
                                        )
                                    }}
                                    chart={<ActivatePlot />}
                                />
                            </ProCard>
                            <ProCard
                                title="数据概览"
                                extra="2019年9月28日 星期五"
                                headerBordered
                                bordered
                                hoverable
                            >
                                <ProCard split="horizontal">
                                    <ProCard split="horizontal">
                                        <ProCard split="vertical">
                                            <StatisticCard
                                                statistic={{
                                                    title: '昨日全部流量',
                                                    value: 234,
                                                    description: <Statistic title="较本月平均流量" value="8.04%" trend="down" />,
                                                }}
                                            />
                                            <StatisticCard
                                                statistic={{
                                                    title: '本月累计流量',
                                                    value: 234,
                                                    description: <Statistic title="月同比" value="8.04%" trend="up" />,
                                                }}
                                            />
                                        </ProCard>
                                        <ProCard split="vertical">
                                            <StatisticCard
                                                statistic={{
                                                    title: '运行中实验',
                                                    value: '12/56',
                                                    suffix: '个',
                                                }}
                                            />
                                            <StatisticCard
                                                statistic={{
                                                    title: '历史实验总数',
                                                    value: '134',
                                                    suffix: '个',
                                                }}
                                            />
                                        </ProCard>
                                    </ProCard>
                                    <StatisticCard
                                        title="流量走势"
                                        chart={
                                            <img
                                                src="https://gw.alipayobjects.com/zos/alicdn/_dZIob2NB/zhuzhuangtu.svg"
                                                width="100%"
                                            />
                                        }
                                    />
                                </ProCard>
                                <StatisticCard
                                    title="流量占用情况"
                                    chart={
                                        <img
                                            src="https://gw.alipayobjects.com/zos/alicdn/qoYmFMxWY/jieping2021-03-29%252520xiawu4.32.34.png"
                                            alt="大盘"
                                            width="100%"
                                        />
                                    }
                                />
                            </ProCard>
                            <ProCard gutter={[16, 16]} wrap ghost>
                                <StatisticCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 12, xl: 12 }}
                                    chart={<BarPlot />} />
                                <StatisticCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 12, xl: 12 }}
                                    chart={<DonutPlot />} />
                            </ProCard>
                            <StatisticCard chart={<LinePlot />} />
                        </ProCard>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <ProCard
                            title="Hoạt động gần đây"
                            bordered
                            headerBordered
                            hoverable
                        >
                            <Timeline
                                items={[
                                    {
                                        children: 'Create a services site 2015-09-01',
                                    },
                                    {
                                        children: 'Solve initial network problems 2015-09-01',
                                    },
                                    {
                                        dot: <ClockCircleOutlined className="timeline-clock-icon" />,
                                        color: 'red',
                                        children: 'Technical testing 2015-09-01',
                                    },
                                    {
                                        children: 'Network problems being solved 2015-09-01',
                                    },
                                ]}
                            />
                        </ProCard>
                    </Col>
                </Row>
            </PageContainer>
    )
}

export default Overview;