import { Liquid } from '@ant-design/plots';
import RingProgressChart from '@ant-design/plots/es/components/ring-progress';
import { PageContainer, ProCard, StatisticCard } from '@ant-design/pro-components';
import { Button } from 'antd';

function Monitor() {
    return (
        <PageContainer
            token={{ paddingInlinePageContainerContent: 40 }}
            header={{
                title: null,
                breadcrumb: {}
            }}
            footer={[
                <Button key="3">Cài lại</Button>,
                <Button key="2" type="primary">
                    Gửi đi
                </Button>,
            ]}
        >
            <ProCard style={{ marginBlockStart: 8 }} direction="column" ghost gutter={[0, 16]}>
                <ProCard gutter={[16, 16]} wrap ghost>
                    <StatisticCard colSpan={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 18 }} style={{ height: 200 }} />
                    <ProCard colSpan={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 6 }} gutter={[16, 16]} wrap ghost style={{ height: 200, backgroundColor: "black" }} >
                        <StatisticCard colSpan={{ xs: 12, sm: 24, md: 24, lg: 24, xl: 24 }} style={{ backgroundColor: "red" }} />
                        <StatisticCard colSpan={{ xs: 12, sm: 24, md: 24, lg: 24, xl: 24 }} style={{ backgroundColor: "yellow" }} />
                    </ProCard>
                </ProCard>
                <ProCard gutter={[16, 16]} wrap ghost>
                    <ProCard title="Tỷ trọng từng loại" layout="center" headerBordered wrap colSpan={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12 }} >
                        <StatisticCard colSpan={{ xs: 24, sm: 6, md: 6, lg: 6, xl: 6 }}
                            chart={<RingProgressChart height={100} width={100} autoFit={true} percent={0.7} color={['#5B8FF9', '#E8EDF3']} />} />
                        <StatisticCard colSpan={{ xs: 24, sm: 6, md: 6, lg: 6, xl: 6 }}
                            chart={<RingProgressChart height={100} width={100} autoFit={true} percent={0.8} color={['#5B8FF9', '#E8EDF3']} />} />
                        <StatisticCard colSpan={{ xs: 24, sm: 6, md: 6, lg: 6, xl: 6 }}
                            chart={<RingProgressChart height={100} width={100} autoFit={true} percent={0.9} color={['#5B8FF9', '#E8EDF3']} />} />
                    </ProCard>
                    <ProCard colSpan={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12 }} gutter={[16, 16]} wrap ghost >
                        <StatisticCard colSpan={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12 }} style={{ height: 200, backgroundColor: "red" }} />
                        <StatisticCard colSpan={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 12 }}
                            title="LiquidPlot"
                            headerBordered
                            chart={< Liquid percent={0.25} outline={{ border: 4, distance: 8 }} wave={{ length: 128 }} />}
                        />
                    </ProCard>
                </ProCard>
            </ProCard>
        </PageContainer>
    )
}

export default Monitor;