import { Bullet } from '@ant-design/plots';

export const ActivatePlot = () => {
    const data = [
        {
            title: '%',
            ranges: [100],
            measures: [78],
            target: 85,
        },
    ];
    const config = {
        data,
        measureField: 'measures',
        rangeField: 'ranges',
        targetField: 'target',
        xField: 'title',
        color: {
            range: '#f0efff',
            measure: '#5B8FF9',
            target: '#3D76DD',
        },
        autoFit: true,
        height: 50
    };
    return <Bullet {...config} />;
};