import {
    FacebookOutlined, LockOutlined, MobileOutlined, InstagramOutlined, UserOutlined, YoutubeOutlined,
    ShopOutlined, ShoppingCartOutlined
} from '@ant-design/icons';
import {
    LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText,
} from '@ant-design/pro-components';
import { Button, Col, InputRef, message, Row, Space, Tabs } from 'antd';
import { css } from '@emotion/css';
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import { useLoginMutation, LoginInput, MeQuery, MeDocument } from '../../services/graphql/generated/schema';
import LanguagePicker from '../../components/LanguagePicker';

// @Styles
const login_background = "url('/images/background_1.svg')";
const login_background_2 = "url('/images/background_2.png')";
const login_dog = "url('/images/login-dog.png')";
const login_vet = "url('/images/login-vet.png')";

type LoginType = 'phone' | 'account';

const waitTime = (time: number = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};

const Login: React.FC = () => {
    const { t, i18n } = useTranslation();
    //const location = useLocation();
    const inputRef = useRef<InputRef>(null);
    const navigate = useNavigate();
    const [loadingManage, setLoadingManage] = useState<boolean>(false);
    const [loadingSale, setLoadingSale] = useState<boolean>(false);
    const [isClickedBtnMan, setIsClickedBtnMan] = useState<boolean>(false);

    const [loginType, setLoginType] = useState<LoginType>('account');
    const [loginUser] = useLoginMutation();

    useEffect(() => {
        document.title = `${t('page.login.title')} - ${process.env.REACT_APP_WEBSITE_NAME}`;
        inputRef.current!.focus({
            cursor: 'all',
        });
    }, [t, inputRef.current?.focus]);

    const langClassName = useEmotionCss(({ token }) => {
        return {
            width: 44,
            height: 42,
            lineHeight: '35px',
            position: 'fixed',
            top: 7,
            right: 18,
            borderRadius: token.borderRadius,
            ':hover': {
                backgroundColor: token.colorBgTextHover,
            },
            display: 'inline-flex',
            justifyContent: 'center',
        };
    });

    const onFinish = async (values: LoginInput) => {
        try {
            isClickedBtnMan ? setLoadingManage(true) : setLoadingSale(true);
            await waitTime();
            const res = await loginUser({
                variables: {
                    loginInput: values,
                },
                update(cache, { data }) {
                    if (data?.login.success) {
                        cache.writeQuery<MeQuery>({
                            query: MeDocument,
                            data: { me: data.login.user }
                        })
                    }
                }
            });

            if (res.data?.login.message != null) {
                if (res.data?.login.success) {
                    message.success((i18n.language === 'vi') ? res.data?.login.message.vi : res.data?.login.message.en);
                    isClickedBtnMan ? navigate('/') : navigate('/sale');
                    //(location.pathname === '/user/login') && navigate('/');
                } else {
                    setLoadingManage(false);
                    setLoadingSale(false);
                    message.error((i18n.language === 'vi') ? res.data?.login.message.vi : res.data?.login.message.en);
                }
            }
        } catch (error: any) {
            setLoadingManage(false);
            setLoadingSale(false);
            message.error(t('page.login.error.server'));
            console.log(error);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        message.error(t('page.login.error.onFinishFailed'));
        console.log('Login Failed:', errorInfo);
    };

    return (
        <div className={css`
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: auto;
        background: #fff;
        
        @media (min-width: ${process.env.REACT_APP_THEME_SCREEN_MD_MIN}) {
            background-image: ${login_vet}, ${login_dog}, ${login_background}, ${login_background_2};
            background-position: left 60px bottom, right bottom, center 110px, center 0px;
            background-repeat: no-repeat;
            background-size: 20%, 15%, 100%, 100%;
        }
        `}>
            <div className={langClassName} data-lang>
                <LanguagePicker />
            </div>
            <div className={css`
                flex: 1 1 0%;
                #padding: 32px 0;

                @media (min-width: ${process.env.REACT_APP_THEME_SCREEN_MD_MIN}) {
                    #padding: 32px 0 24px;
                }
            `}>
                <LoginForm
                    //contentStyle={{ width: 400 }}
                    logo="/images/dog-paw.png"
                    title={<span className={css`
                        font-family: "f Fallow", sans-serif;
                        color: ${process.env.REACT_APP_THEME_PRIMARY_COLOR};
                    `}>PetZoo.VN</span>}
                    subTitle={t("page.login.subTitle")}
                    initialValues={{ autoLogin: true }}
                    onFinish={async (values) => { await onFinish(values as LoginInput); return true }}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    submitter={{
                        render: (props, _doms) => {
                            return [
                                <Row gutter={[8, 0]}>
                                    <Col flex={1}>
                                        <Button type="default" key="manage" loading={loadingManage} icon={<ShopOutlined />} size="large" block
                                            style={{ fontWeight: 600 }}
                                            onClick={() => { setIsClickedBtnMan(true); props.form?.submit?.() }}>
                                            {t("page.login.btnManage")}
                                        </Button>
                                    </Col>
                                    <Col flex={1}>
                                        <Button type="primary" key="sale" loading={loadingSale} icon={<ShoppingCartOutlined />} size="large" block
                                            style={{ fontWeight: 600 }}
                                            onClick={() => props.form?.submit?.()}>
                                            {t("page.login.btnSale")}
                                        </Button>
                                    </Col>
                                </Row>
                            ];
                        },
                    }}
                    actions={
                        <Space>
                            {t('page.login.infoSupport')}
                            <FacebookOutlined key="FacebookOutlined"
                                className={css`
                                margin-left: 8px;
                                color: rgba(0, 0, 0, 0.2);
                                font-size: 24px;
                                vertical-align: middle;
                                cursor: pointer;
                                transition: color 0.3s;

                                &:hover {
                                    color: ${process.env.REACT_APP_THEME_PRIMARY_COLOR};
                                }
                            `} />
                            <InstagramOutlined key="InstagramOutlined"
                                className={css`
                                margin-left: 8px;
                                color: rgba(0, 0, 0, 0.2);
                                font-size: 24px;
                                vertical-align: middle;
                                cursor: pointer;
                                transition: color 0.3s;

                                &:hover {
                                    color: ${process.env.REACT_APP_THEME_PRIMARY_COLOR};
                                }
                            `} />
                            <YoutubeOutlined key="YoutubeOutlined" className={css`
                                margin-left: 8px;
                                color: rgba(0, 0, 0, 0.2);
                                font-size: 24px;
                                vertical-align: middle;
                                cursor: pointer;
                                transition: color 0.3s;

                                &:hover {
                                    color: ${process.env.REACT_APP_THEME_PRIMARY_COLOR};
                                }
                            `} />
                        </Space>
                    }
                >
                    <Tabs
                        centered
                        activeKey={loginType}
                        onChange={(activeKey) => {
                            inputRef.current!.focus({
                                cursor: 'all',
                            });
                            setLoginType(activeKey as LoginType)
                        }
                        }
                        items={[{
                            label: t('page.login.accountLogin'),
                            key: 'account',
                        },
                        {
                            label: t('page.login.phoneLogin'),
                            key: 'phone',
                            disabled: true
                        },]}
                    />
                    {loginType === 'account' && (
                        <>
                            <ProFormText
                                name="usernameOrEmail"
                                fieldProps={{
                                    ref: inputRef,
                                    size: 'large',
                                    prefix: <UserOutlined className="prefixIcon" />,
                                }}
                                placeholder={t('page.login.inputUsernameOrEmail') || undefined}
                                rules={[
                                    {
                                        required: true,
                                        message: t('page.login.plsInputUsernameOrEmail') || undefined,
                                    },
                                ]}
                            />
                            <ProFormText.Password
                                name="password"
                                fieldProps={{
                                    size: 'large',
                                    prefix: <LockOutlined className="prefixIcon" />,
                                }}
                                placeholder={t('page.login.inputPassword') || undefined}
                                rules={[
                                    {
                                        required: true,
                                        message: t('page.login.plsInputPassword') || undefined,
                                    },
                                ]}
                            />
                        </>
                    )}
                    {loginType === 'phone' && (
                        <>
                            <ProFormText
                                //disabled
                                fieldProps={{
                                    ref: inputRef,
                                    size: 'large',
                                    prefix: <MobileOutlined className="prefixIcon" />,
                                }}
                                name="mobile"
                                placeholder={t('page.login.inputPhoneNumber') || undefined}
                                rules={[
                                    {
                                        required: true,
                                        message: t('page.login.plsInputPhoneNumber') || undefined,
                                    },
                                    {
                                        pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
                                        message: t('page.login.errInvalidPhoneNumber') || undefined,
                                    },
                                ]}
                            />
                            <ProFormCaptcha
                                disabled
                                fieldProps={{
                                    size: 'large',
                                    prefix: <LockOutlined className="prefixIcon" />,
                                }}
                                captchaProps={{
                                    size: 'large',
                                }}
                                placeholder={t('page.login.inputVerificationCode') || undefined}
                                captchaTextRender={(timing, count) => {
                                    if (timing) {
                                        return `${count} ${t('page.login.countSecond')}`;
                                    }
                                    return t('page.login.getCode');
                                }}
                                name="captcha"
                                rules={[
                                    {
                                        required: true,
                                        message: t('page.login.plsInputVerificationCode') || undefined,
                                    },
                                ]}
                                onGetCaptcha={async () => {
                                    message.success(t('page.login.sentSMSCode'));
                                }}
                            />
                        </>
                    )}
                    <div
                        style={{
                            marginBlockEnd: 24,
                        }}
                    >
                        <ProFormCheckbox noStyle name="autoLogin">
                            {t('page.login.rememberMe')}
                        </ProFormCheckbox>
                        <Link
                            style={{
                                float: 'right',
                            }}
                            to="/user/forgot-password"
                        >
                            {t('page.login.forgotPassword')}
                        </Link>
                    </div>
                </LoginForm>
            </div >
            <Space direction="vertical">
                <Footer />
            </Space>
        </div >
    );
};

export default Login;